:root {
  --iscoreSec: rgba(63, 147, 211, 1);
  --iscorePrim: #1a5785;
  --iscoreGrey: rgba(185, 185, 195, 1);
  --links: #1890ff;
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsSemibold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Cairo";
  src: url("./fonts/Cairo-Regular.ttf");
}
.App {
  overflow: hidden;
  width: 100%;
  background: #fcfcfc;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light .ant-menu-submenu-title,
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light .ant-menu-item {
  padding-left: 24px !important;
}

.rtl *:not(.fa, .fas, .far) {
  font-family: "Cairo", sans-serif !important;
}

.ltr *:not(.fa, .fas, .far) {
  font-family: "PoppinsRegular", sans-serif;
}

.pagePadding {
  padding-inline-start: 40px;
  padding-inline-end: 100px;
  padding-block: 30px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--iscoreSec);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--iscorePrim);
}

label {
  color: var(--iscoreSec);
}
.ant-input-affix-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.ant-input-suffix {
  font-size: 20px;
  position: absolute;
  top: 0px;
}

.second .ant-input-suffix {
  position: relative;
}

.flex,
.flex-center,
.flex-space-between,
.flex-end {
  display: flex !important;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-space-between {
  align-items: center;
  justify-content: space-between;
}

.showMoreBtn {
  color: #3f93d3;
  border: none;
  background-color: transparent;
}

.downloadBtn {
  color: #1a5785;
  border: none;
  background-color: transparent;
}

.AcceptSelectedBtn {
  background-color: #52c41a;
  border: 1px solid #52c41a;
}
.RejectSelectedBtn {
  background-color: #ff4d4f;
  border: 1px solid #ff4d4f;
}

.AcceptSelectedBtn,
.RejectSelectedBtn {
  padding: 5px 20px;
  color: #ffffff;
  margin: 0px 5px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
}

.AcceptSelectedBtn.dis,
.RejectSelectedBtn.dis {
  cursor: not-allowed;
  opacity: 0.5;
}
.no-margin {
  margin: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fontSize {
  font-size: 10px;
  margin-inline-end: 5px;
}

.table-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.table-wrapper .table-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 22px;
  color: #242731;
  font-family: "PoppinsBold", sans-serif !important;
}

.table-wrapper .table-col {
  border-inline-end: 1px solid #d9d9d9;
  border-block-start: 1px solid #d9d9d9;
  padding-inline-start: 20px;
  padding-block: 10px;
}
.table-wrapper td {
  border-inline-end: 1px solid #d9d9d9;
  border-block-start: 1px solid #d9d9d9;
  padding-inline-start: 20px;
  padding-block: 10px;
}
.table-wrapper p {
  margin-bottom: 0px;
}

.table-wrapper .rejection-row {
  background-color: var(--iscoreSec);
  color: white;
  font-size: 24px;
  font-family: "PoppinsSemibold", sans-serif;
}

.table-wrapper .amount-row {
  text-align: center;
  font-size: 16px;
  font-family: "PoppinsSemibold", sans-serif;
  color: #242731d9;
}

.table-wrapper .amount-value-row {
  text-align: center;
  font-size: 64px;
  color: #242731;
  font-family: "PoppinsSemibold", sans-serif;
}
.table-wrapper table {
  width: 100%;
}
.table-wrapper .row-content {
  color: #242731;
  font-size: 14px;
  font-family: "PoppinsSemibold", sans-serif;
  display: flex;
  align-items: center;
}
.semibold {
  font-family: "PoppinsSemibold", sans-serif;
  color: #242731;
}
/* .table-wrapper *{
  display: flex;
  align-items: center;
} */

.reports-title {
  color: #24273166;
  font-weight: 600;
  font-size: 20px;
  line-height: 56px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tableheader {
  background: #f0f0f0 !important;
  padding: 10px;
}
.tableheader p {
  font-weight: 600;
  font-size: 14px;
  color: rgba(36, 39, 49, 0.85) !important;
  margin-bottom: 0px !important;
}
.rdt_Table {
  border: 1px solid #e2e4e5;
  border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.icon-wrapper svg {
  fill: red;
}

.data {
  margin-inline-end: 5px;
}
.more-details {
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
}
.warning-btn {
  background: #fff0d0 !important;
  border: 1px solid #ffab00 !important;
  border-radius: 2px;
  color: #ffab00 !important;
}
.uploadDate {
  color: rgba(0, 0, 0, 0.45);
}
.uploadedFileName {
  font-size: 14px;
  color: #242731;
  padding: 10px;
}
.anticon-paper-clip {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  background: #e6f7ff;
}

.ant-table-cell {
  word-break: keep-all !important;
  white-space: nowrap !important;
}
/* .antd-table-custom-class thead th, .antd-table-custom-class tbody 
 td
 {    white-space: nowrap !important;
      word-break:break-word !important;
      word-break: keep-all !important;
 } */

.bulk-card {
  background: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 15px;
}

.bulk-card svg {
  fill: rgba(0, 0, 0, 0.45);
  margin-bottom: 4px;
}

a {
  color: var(--iscorePrim);
  text-decoration: unset !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  text-align: unset !important;
}

.ant-form-item-label label {
  font-weight: 500;
  color: var(--iscoreSec) !important;
}
.ltr .ant-steps-item-title::after {
  left: 100%;
}

.rtl .ant-steps-item-title::after {
  right: 100%;
}

.title1 {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.fa-check-circle {
  color: #52c41a;
  font-size: 18px;
}

.fa-times-circle {
  font-size: 18px;
  color: #ff4d4f;
}
.ltr .modal-content {
  border-radius: 12px !important;
  font-family: "PoppinsRegular", sans-serif;
}

.rtl .modal-content {
  font-family: "Cairo", sans-serif !important;
}

.solve-conflict-btn {
  background: var(--iscoreSec) !important;
  color: white;
  transition: all 0.5s;
  width: 100%;
  border: none !important;
}

.start-dispute-btn {
  width: 100%;
  color: var(--iscoreSec) !important;
  border: 1px solid var(--iscoreSec) !important;
  transition: all 0.5s;
  background: white !important;
}

.start-dispute-btn:hover {
  background: var(--iscoreSec) !important;
  color: white !important;
}

.solve-conflict-btn:hover {
  background: var(--iscorePrim) !important;
}

.modal-title {
  font-size: 32px;
  color: var(--iscoreSec);
  font-weight: 600;
}

.conflict-text {
  font-weight: 600;
  font-size: 23px;
}
.data.text {
  color: black;
  font-size: 16px;
}
.data-place {
  color: var(--iscoreSec);
  margin-inline-start: 5px;
  font-size: 12px;
}

.conflict-card .ant-tooltip-inner {
  background-color: white !important;
  font-family: "PoppinsRegular", sans-serif !important;
  padding: 0px !important;
  display: flex !important;
  flex-direction: column;
  width: 280px !important;
}

.conflict-card .tooltipTitle {
  font-size: 16px;
  background-color: #e2e4e5;
  color: rgba(36, 39, 49, 0.85);
  padding: 5px 15px;
  margin-bottom: 0px !important;
}

.conflict-card .reportType {
  font-size: 14px;
  color: black;
  margin-bottom: 0px;
}

.conflict-card .wrapper-padding {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.conflict-card .ant-tooltip-arrow-content {
  background-color: white !important;
}

.conflict-card .reportBtn {
  background: var(--iscoreSec);
  box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
  padding: 5px 10px;
  border-radius: 10px;
  border: unset;
  color: white;
  font-weight: 600;
  align-self: flex-end;
}

/*--------------------reports-------------------*/

.report-title {
  font-size: 41px;
  font-weight: 700;
}
.customSelect {
  position: relative;
}

.report-filters {
  font-size: 23px;
  margin-bottom: 0px;
  font-weight: 500;
}

.filters-subtext {
  font-size: 14px;
  color: var(--iscoreGrey);
}

.customSelect label {
  font-weight: 500;
}

.customSelect .ant-select-selector {
  border-radius: 8px;
}

.customSelect .ant-select {
  width: 100% !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--iscoreSec) !important;
}

.ant-table-thead > tr > th,
.ant-table table {
  text-align: start !important;
}
.ant-table-thead > tr > th {
  font-weight: 600 !important;
}
.ant-pagination {
  direction: ltr;
  text-align: center;
}

.disputeSelect {
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
}

.disputeSelect .ant-select-selector {
  background: var(--iscoreSec) !important;
  height: 43px !important;
  align-items: center !important;
}

.disputeSelect .ant-select-arrow {
  color: white !important;
}

.inputWidth {
  width: 60% !important;
}

.search-input {
  height: 45px;
  border-radius: 4px 0px 0px 4px;
}

.verify-select .ant-select-selector {
  background-color: var(--iscoreSec) !important;
  color: white !important;
}

.verify-select .ant-select-arrow,
.verify-select .ant-select-selection-placeholder {
  color: white !important;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin-inline-start: 32px !important;
}

.error {
  color: red;
}

/*--------------------reports settings-------------------*/
.settings .ant-form-item {
  border-bottom: 1px solid #f4f6f7;
  padding: 15px 0px;
  margin: 0px !important;
}

.settings label::after {
  content: "" !important;
}

.settings .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
}

.settings .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

/*--------------------Email configuration-------------------*/

.ant-spin-dot-item {
  background-color: var(--iscorePrim) !important;
}

button .ant-spin-dot-item {
  background-color: white !important;
}

/********************************/
.uploadWrapper .ant-upload-list {
  display: none;
}

/*--------------------Invoicing Mechanism-------------------*/

.invoice-table .id-data {
  color: var(--links);
}

.invoice .ant-form-item-control-input-content {
  display: flex !important;
}

.invoice .ant-picker {
  border: unset !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.captcha-icon {
  border: 1px solid #d1d6e0 !important;
  background: white;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 95px;
}

.captcha-icon .anticon svg {
  fill: var(--iscorePrim) !important;
  cursor: pointer;
  margin-inline-start: 10px;
  width: 20px;
  height: 20px;
}

.captcha-icon img {
  width: 200px;
  border-radius: 8px;
}

.tableRow {
  background-color: white;
  padding: 10px;
  border: 1px solid #e2e4e5;
}

.tableheader {
  border: 1px solid #e2e4e5;
  border-radius: 8px 8px 0px 0px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--iscorePrim) !important;
}

.ant-tabs-ink-bar {
  background: var(--iscorePrim) !important;
}

.pending {
  background: #1a578526;
  border: 1px solid #1a5785;
  color: #1a5785;
  padding: 5px;
}

.accepted {
  background: #f3f8ed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
  padding: 5px;
}

.rejected {
  background: #ff4d4f1a;
  border: 1px solid #ff4d4f;
  color: #ff4d4f;
  padding: 5px;
}

.description {
  font-size: 24px;
  font-weight: 600;
}

.ant-btn-primary {
  background: var(--iscoreSec) !important;
  border-color: var(--iscoreSec) !important;
}

.ant-btn-dangerous,
.ant-btn-primary {
  border-radius: 8px !important;
}

/* Charts */

.dougnut-chart {
  background: white;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.dougnut-chart .canvas-container canvas {
  z-index: 2;
}

.dougnut-chart .canvas-container {
  z-index: 0;
}

.total-chart-data {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--iscoreSec);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 75px 75px;
  font-weight: bold;
  color: white;
  font-size: 24px;
  z-index: 0;
  align-self: center;
}

.chart-position1 {
  top: 30%;
}

.chart-position2 {
  top: 35%;
}

.canvas-container {
  height: 450px;
}

/* Footer */

.ant-layout-footer {
  display: flex !important;
  color: #b5b5b5 !important;
  font-size: 18px !important;
  flex-direction: column !important;
  padding-inline: 10px !important;
  text-align: start;
}

.rtl .ant-layout-footer {
  align-items: flex-start !important;
}

.ltr .ant-layout-footer {
  align-items: flex-end !important;
}

.ant-layout-footer img {
  filter: grayscale();
  max-width: 123px;
}

.cheques-filter {
  background: white;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 20px;
}

.cheques-filter .anticon-reload {
  font-size: 24px;
  background: var(--iscoreSec);
  border-radius: 5px;
  padding: 5px;
}

.cheques-filter .anticon-reload svg {
  fill: white;
}

/******************info card*********************/
.informationCard {
  background: rgba(63, 147, 211, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.informationCard .anticon-info-circle {
  color: #3f93d3;
  margin-right: 10px;
}

.informationCard .informationCardTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0em !important;
}

.informationCard .informationCardSubtitle {
  font-size: 14px;
  margin-bottom: 0em !important;
}
.rejectionForm label {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85) !important;
}

.oldDiv {
  /* Primary/100 */

  border: 1.5px solid #1a5785;
  border-radius: 2px 2px 0px 0px;
  padding: 10px;
}

.applyFilterBtn {
  background: #1a5785 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 8px !important;
  color: #fff !important;
  margin-inline-start: 15px;
}
.dashboardFilter {
  border-radius: 10px !important;
  width: 20%;
  padding: 10px;
}
.ant-switch-checked {
  background-color: #1a5785 !important;
}
.configBtn:active,
.configBtn:focus,
.configBtn:hover {
  color: #1a5785 !important;
  border-color: #1a5785 !important;
}

.swichBtn:active,
.swichBtn:focus,
.swichBtn:hover,
.swichBtn {
  width: -webkit-fill-available;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px !important;
}
.switchTxt {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(36, 39, 49, 0.85);
}
.rejectionLogsText,
.rejectionLogsText a {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
}

.idText {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1890ff;
}

.disputeInfoBtn,
.disputeInfoBtn:hover,
.disputeInfoBtn:active {
  padding: 5px 16px !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  border-color: transparent !important;
}
.disputeInfoRow {
  background: #fafafa;
  border: 0.5px solid #f0f0f0;
  padding: 10px;
}

.pendingBtn,
.pendingBtn:hover,
.pendingBtn:active {
  color: #ffb82e !important;
  background: #fff6e6 !important;
  border: 1px solid #ffb82e !important;
  border-radius: 2px;
}
.bold {
  font-weight: bold;
}

.summrytable td,
.summrytable th {
  border: 1px solid #f2f2f2;
  padding: 20px 5px;
}

.summrytable tr:nth-child(even) {
  background-color: #f2f2f2;
}
