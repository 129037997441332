:root {
  --iscoreSec: rgba(63, 147, 211, 1);
  --iscorePrim: #1a5785;
  --iscoreGrey: rgba(185, 185, 195, 1);
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("../fonts/Poppins-Medium.ttf");
}
.loginContainer {
  background: rgba(26, 87, 133, 1);
  height: 100vh;
}

.subText {
  /* font-family: "Poppins Medium"; */
  font-weight: 500;
  font-size: 14px;
  color: var(--iscoreGrey);
}
.first {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-block-start: 150px !important;
  padding-inline-start: 50px !important;
  padding-block-end: 50px !important;
}

.ltr .second {
  border-radius: 50px 0px 0px 50px;
}

.rtl .second {
  border-radius: 0px 50px 50px 0px;
}
.second {
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100vh;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  font-weight: bold;
  flex-direction: column;
  padding: 3% !important;
}

.second form {
  min-width: 50%;
}

.language {
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
}

.ltr .language a {
  font-size: 14px;
  font-family: "Cairo", sans-serif;
}

.rtl .language a {
  font-family: "PoppinsMedium", sans-serif;
}

.second .anticon svg {
  fill: var(--iscoreGrey);
}

.second .ant-input-affix-wrapper {
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #d1d6e0 !important;
}
.ant-form-item {
  margin-bottom: 16px !important;
  margin-top: 16px;
}
.second .title {
  font-size: 32px;
  margin: 0px;
  line-height: 1.1;
}
.loginInput:first-child {
  /* margin-top: 30px !important; */
}
.loginBtn {
  background: rgba(63, 147, 211, 1) !important;
  border-radius: 4px !important;
  width: 100%;
  height: 48px !important;
  color: white !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: start;
  align-items: flex-start;
}
.welcome {
  font-size: 48px;
  color: white;
  font-weight: bold;
  text-align: start;
  margin: 0px;
  line-height: 1.2;
}

.ant-input-prefix {
  border-inline-end: 1px solid var(--iscoreGrey);
  padding-inline-end: 10px;
}

.logo {
  width: 175px;
}

.passwordLinks {
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-decoration-line: underline;
  color: #1890ff;
}

.resetCard {
  background: #ffffff;
  /* drop-shadow / 0.12+0.8+0.5 */

  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
}
.resetCard .text1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
}
.resetBtn1,
.resetBtn1:hover,
.resetBtn1:active,
.resetBtn1:focus {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  margin-inline-end: 10px;
}
.resetBtn2,
/* .resetBtn2:hover, */
.resetBtn2:active,
.resetBtn2:focus {
  background: var(--iscoreSec) !important;
  border-radius: 2px;
  border-color: var(--iscoreSec) !important;
  border: unset;
  box-shadow: unset;
  color: #fff !important;
  font-weight: 600;
}

.resetBtn2:hover {
  background: var(--iscorePrim) !important;
  border-color: var(--iscorePrim) !important;
}
