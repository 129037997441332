:root {
  --iscoreSec: rgba(63, 147, 211, 1);
  --iscorePrim: #1a5785;
  --iscoreGrey: rgba(185, 185, 195, 1);
}

.logout {
  position: absolute;
  bottom: 0px;
}
/* .settingsLink {
  position: absolute;
  bottom: 15px;
  left: 0px;
} */

.ant-layout-sider {
  position: sticky !important;
  min-height: 90vh;
  height: fit-content;
  background: white !important;
  top: 20px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  border-radius: 15px;
  margin-inline-end: 10px;
  max-height: 100vh;
}


.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  border-radius: 15px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 47px !important;
  max-width: 80px !important;
  min-width: 47px !important;
  width: 47px !important;
}
.ltr .ant-layout-sider-trigger {
  clip-path: inset(-8px -8px -8px 0px);
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 15%) !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: white !important;
  width: 20px !important;
  height: 80px !important;
  position: absolute !important;
  right: -19px;
  bottom: 50% !important;
  border-radius: 0px 10px 10px 0px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rtl .ant-layout-sider-trigger {
  clip-path: inset(-8px 0px -8px -8px);
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 15%) !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%) !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: white !important;
  width: 20px !important;
  height: 80px !important;
  position: absolute !important;
  left: -19px;
  bottom: 50% !important;
  border-radius: 10px 0px 0px 10px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.image-div {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.ant-menu-item svg,
.ant-menu-submenu svg {
  color: var(--iscoreGrey);
}

.icon svg{
  fill: red;
}
.ant-menu-item-selected svg {
  color: var(--iscorePrim) !important;
}

.ant-menu-title-content{
  color: black !important;
}

.general {
  font-size: 12px;
  line-height: 16px;
  padding: 20px 20px 8px;
  text-transform: uppercase;
  margin-top: 20px;
  color: #6d7175;
}

.activeItem{
  background: #e6f7ff !important;
}

.activeItem svg{
  color: var(--iscorePrim) !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--iscorePrim) !important;
}

.ant-menu-item-icon  a {
  color: var(--iscorePrim) !important;
}



@media (max-width: 599px) {
  .MuiToolbar-root {
    display: block !important;
  }
}
.sidemenu {
  position: relative;
}

.logo-animation {
  animation-name: example;
  animation-duration: 1s;
  animation-direction: normal;
  animation-timing-function: ease-in;
}

@keyframes example {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
