.sectionHolder {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  /* min-height: 50vh; */
  margin-top: 20px;
}

.pageTitle {
  font-family: "PoppinsBold", sans-serif !important;
  font-weight: 600;
  font-size: 41px;
  color: #242731;
}
.pageSubTitle {
  font-family: "PoppinsSemibold", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.sectionHolder .actions {
  font-family: "PoppinsRegular", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #1a5785;
  border: none;
  background-color: transparent;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.tableOptions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.tableOptions .logsLink {
  padding: 5px 16px;
  background: transparent;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.tableOptions .addNewCurrencyLink {
  padding: 5px 16px;
  color: #ffffff;
  background: #3f93d3;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
}

.backtoAllBtn {
  padding: 5px 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-family: "PoppinsSemibold", sans-serif !important;
}

.backtoAllBtn:hover {
  color: inherit;
}

.currancyFormTitle {
  font-family: "PoppinsBold", sans-serif !important;
  font-weight: 600;
  font-size: 28px;
  color: #242731;
}
.currancyFormsubTitle {
  font-family: "PoppinsRegular", sans-serif;
  font-weight: 400;
  font-size: 14px;

  color: rgba(36, 39, 49, 0.6);
}

.sectionHolder .ant-form-item-label label {
  color: rgba(0, 0, 0, 0.85) !important;
}

.formBtnsHolders {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 20px;
}

.sectionHolder .ant-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
}

.logsChangeBtn {
  background-color: transparent;
  border: none;
  color: #1890ff;
}

.logsSectionModal .btn-close {
  margin: 0;
}
