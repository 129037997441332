:root {
  --iscoreSec: rgba(63, 147, 211, 1);
  --iscorePrim: #1a5785;
  --iscoreGrey: rgba(185, 185, 195, 1);
  --links: #1890ff;
}

.title {
  font-size: 32px;
  margin: 25px 0px 0px 0px;
  line-height: 1;
  font-weight: 700;
  font-family: "PoppinsBold" !important;
}

.title-upload {
  font-size: 32px;
  line-height: 1;
  font-weight: 600;
  font-family: "PoppinsBold" !important;
  margin: 25px 0px 10px 0px;
}

.typeChoosen {
  font-size: 14px;
  color: var(--iscoreGrey);
}

.subTitle {
  font-size: 23px;
  margin: 0px;
  font-weight: 600;
}

.payment-title {
  font-size: 24px;
  color: var(--iscorePrim);
  font-weight: 600;
}
.subTitle-bold {
  font-family: "PoppinsBold" !important;
  font-weight: 600;
}

.subHeader {
  font-size: 14px;
  font-weight: 400;
  color: var(--iscoreGrey);
}

.formWrapper .anticon svg {
  fill: var(--iscoreSec);
}

.formWrapper,
.formWrapper2 {
  border: 1px solid rgba(226, 228, 229, 1);
  border-radius: 40px;
  padding: 35px 35px 20px 35px;
  background: white;
}

.formWrapper3 {
  border: 1px solid rgba(226, 228, 229, 1);
  background: white;
  padding: 20px 25px 20px 25px;
  border-radius: 20px;
}
.formWrapper .ant-input {
  border: none !important;
  border-bottom: 1px solid rgba(226, 228, 229, 1) !important;
  transition: all 0.5s;
}

.formWrapper #notes {
  border: 1px solid rgba(226, 228, 229, 1) !important;
}

.entityModal .ant-input,
.ant-select-selector {
  border: none !important;
  border-bottom: 1px solid rgba(226, 228, 229, 1) !important;
  transition: all 0.5s;
}
.formWrapper .ant-input-affix-wrapper {
  border: none;
  box-shadow: none;
  padding: 4px 0px !important;
}

.formWrapper .ant-input:focus,
.entityModal .ant-input:focus,
.ant-select-selector:focus {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid var(--iscoreSec) !important;
}

.ant-steps {
  width: 55% !important;
  margin: 35px 0px;
}

.inquiry .ant-steps {
  width: 35% !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(63, 147, 211, 1) !important;
  font-family: "PoppinsBold";
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(185, 185, 195, 1) !important;
  height: 2px !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-family: "PoppinsBold";
  color: rgba(185, 185, 195, 1) !important;
}

.ant-picker {
  width: 100%;
}

.skipBtn {
  border: 1px solid var(--iscoreSec);
  background: white;
  width: 194px;
  height: 40px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 10px;
  color: var(--iscoreSec);
  font-weight: 600;
  margin: 0px 10px;
}

.skipBtn:hover,
.skipBtn:focus {
  background: var(--iscoreSec);
  color: white;
  border: unset;
  box-shadow: unset;
}
.formBtn,
.cancelBtn,
.noBorderFormBtn,
.noRadiusBtn {
  height: 45px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border: unset;
  color: white;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.5s;
}
.formBtn,
.cancelBtn,
.noBorderFormBtn {
  border-radius: 8px;
}

.formBtn,
.noRadiusBtn {
  padding: 5px 30px;
  background: var(--iscoreSec);
}

.formBtn:hover,
.formBtn:active,
.formBtn:focus {
  background: var(--iscorePrim) !important;
  color: white !important;
}
.formBtn:disabled {
  background: #e2e4e5 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 10px;
  color: rgba(36, 39, 49, 0.4) !important;
}
.text-end {
  justify-content: end;
}
.btnDiv {
  display: flex;
  flex-direction: column;
}
.skipBtn button {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  height: 50px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 15px;
  width: 70%;
  margin: 0px 15px 15px;
}
.btnDiv button {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  height: 50px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 15px;
  width: 70%;
  margin: 0px auto 15px;
}
.btnDiv .submitBtn {
  background: var(--iscoreSec);
  color: #ffffff;
}
.btnDiv .draftBtn {
  color: var(--iscoreSec);
  border: 1px solid var(--iscoreSec);
}
.btnDiv .deleteBtn {
  background: #b9b9c4;
  color: #ffffff;
}

.cancelBtn {
  border: 1px solid var(--iscoreSec);
  margin: 0px 5px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  color: var(--iscoreSec);
  padding: 5px 30px;
}

.formSpan {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(36, 39, 49, 0.4);
}

.material-textfield {
  position: relative;
}

.formLabel {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.formInput.ant-input {
  width: 100%;
}
.formInput.ant-input[disabled] {
  border: 1px solid #b9b9c4;
  color: #b9b9c4;
  border-color: #b9b9c4 !important;
  background-color: white;
}
.formInput,
.formInput:focus {
  font-size: 14px;
  outline: none;
  border: 1px solid rgba(36, 39, 49, 0.85);
  border-radius: 8px;
  padding: 5px;
  color: rgba(36, 39, 49, 0.85);
  transition: 0.1s ease-out;
  border-color: rgba(36, 39, 49, 0.85) !important;
  width: 100%;
  background-color: white;
  box-shadow: unset !important;
}

.ltr .material-textfield .ant-form-item ~ .formLabel {
  top: 0;
  display: table;
  transform: translateY(-50%) scale(0.9);
}
.rtl .material-textfield .ant-form-item ~ .formLabel {
  top: 0;
  right: 0;
  display: table;
  transform: translateY(-50%) scale(0.9);
}

.formLabelActive {
  color: #000;
}
.formLabelDisabled {
  color: #b9b9c4;
}

.formInput:not(:placeholder-shown) + .formLabel {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.paymentEditBtn {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--links);
  margin-inline-end: 5px;
}
.anticon-edit {
  color: var(--links);
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-picker.ant-picker-disabled,
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: white;
}

.ant-select-selection-item-content,
.ant-picker-input > input[disabled],
.ant-input[disabled] {
  color: black;
}

.tempBtn,
.tempBtn:hover,
.tempBtn:active {
  color: #1890ff !important;
  background-color: #fff !important;
  border: unset !important;
  box-shadow: unset !important;
}
