.header {
  background-color: white;
  padding: 5px;
  /* width: 100%; */
  box-shadow: 0px 10px 25px #0000002b;
}

.ant-dropdown-trigger {
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.ant-dropdown-trigger span {
  margin-inline-start: 10px;
}

.ant-layout-header {
  height: 48px !important;
  padding: 0px !important;
  line-height: 64px;
  background: white !important;
  position: sticky;
  z-index: 1;
  width: 99%;
  display: flex;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  border-radius: 15px;
  margin-top: 20px;
  transition: all 1s;
  justify-content: flex-end;
  align-items: center;
}

.ant-layout-header .ant-menu-root {
  /* height: 62px; */
  /* position: absolute; */
  width: 10%;
}

.ant-menu-title-content {
  text-align: start;
}
.btn1 a,
.btn2 a {
  color: white !important;
}

.ant-dropdown-link {
  font-size: 16px;
}

.navBtn,
.navBtn:focus {
  background-color: #ffffff !important;
  border: unset !important;
  color: #939494 !important;
  font-size: 17px;
  box-shadow: unset !important;
}
.navIcon {
  font-size: 30px !important;
}
.navDropDown {
  border: unset !important;
  box-shadow: 0px 17px 32px #00000038;
}
.userName {
  font-size: 16px;
  /* color: #707070; */
  letter-spacing: 0.65px;
  font-weight: 600;
}
.btnsDiv {
  margin-top: 20px;
}

.cursor {
  cursor: pointer;
}

.ant-table-content {
  overflow-x: auto;
}

.logout {
  margin-inline-start: 20px !important;
}

@media (max-width: 700px) {
  .homeLogo {
    width: 130px;
  }
  .header .btn2 {
    width: unset;
  }
  .header .btn1 {
    width: unset;
  }
  .btnsDiv {
    margin-top: unset;
  }
  .header {
    padding: 10px 0px;
  }
}
