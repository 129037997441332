.ReportParametersPage {
  background-color: white;

  border-radius: 8px;

  padding: 20px;
}

.reportTitle {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin: 0;
}

.reportTypeStandard {
  background: #e7f4ff;
  color: #1a5785;
  border: 1px solid rgba(26, 87, 133, 0.25);
  border-radius: 2px;
  height: fit-content;
  margin: 0 20px;
  padding: 2px 5px;
  font-weight: 400;
  font-size: 12px;
}

.reportTypeCustomize {
  background: #f6ffed;
  color: #52c41a;
  border: 1px solid #b7eb8f;
  border-radius: 2px;
  height: fit-content;
  margin: 0 20px;
  padding: 2px 5px;
  font-weight: 400;
  font-size: 12px;
}

.reportBankName {
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}

.SwitchOnBtn {
  width: 45%;
  font-weight: 500;
  font-size: 16px;
  background-color: #52c41a;
  color: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #52c41a;
  min-height: 44px;
}

.SwitchOffBtn {
  width: 45%;
  font-weight: 500;
  font-size: 16px;
  background-color: #ff4d4f;
  color: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #ff4d4f;
  min-height: 44px;
}

.cancleBtn {
  font-weight: 500;
  font-size: 16px;
  width: 45%;
  background-color: #ffffff;
  color: #344054;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  min-height: 44px;
}

.TableViewSwitchBtns {
  min-width: 218px;
  min-height: 36px;
  border: none;
  color: rgba(0, 0, 0, 0.85);
  background: #ffffff;
  border-radius: 4px;
}

.ActiveTableViewSwitchBtns {
  min-width: 218px;
  min-height: 36px;
  border: none;
  color: #e7f4ff;
  background: #1a5785;
  border-radius: 4px;
}
