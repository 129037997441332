:root {
  --iscoreSec: rgba(63, 147, 211, 1);
  --iscorePrim: #1a5785;
  --iscoreGrey: rgba(185, 185, 195, 1);
  --links: #1890ff;
}


.dashboardCard {
  padding: 12px 12px 12px 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 4px 4px 15px -21px rgba(0, 0, 0, 0.25);
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardArrow,
.cardArrow:hover,
.cardArrow:active {
  margin: 15px 0px 0px 0px;
  background: rgba(63, 147, 211, 0.25) !important;
  border: unset !important;
  border-radius: 5px;
  box-shadow: unset !important;
  color: #1a5785 !important;
  padding: 8px 18px;
  display: flex;
  align-items: center;
}
.recent h5 {
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.dashboardCard .status {
  font-size: 15px;
  line-height: 24px;
  color: #1890ff;
}
.recent {
  background: #ffffff;
  border-radius: 10px;
}
.recent .ant-table-thead {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.tableCol1 {
  font-size: 14px;
  color: rgba(36, 39, 49, 0.6);
}

.report-card {
  background: white;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
}

.report-card p{
  margin-bottom: 0px;
  font-size: 20px;
  color: var(--iscorePrim);
  font-weight: 600;
}

.report-card .cardArrow{
  margin-top: 0px;
  padding: 14px 18px;
}
